<template>
  <div>
    <PageTitle :heading="$t('generic.lang_salesPerHour')" :icon="icon" :subheading="$t('generic.lang_salesPerHour')"></PageTitle>

    <div class="app-main__inner">
      <sales-per-hour-component/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import SalesPerHourComponent from "@/components/analytics/warewanlytics/SalesPerHourComponent";

export default {
  name: "SalePerHourView",

  components: {
    SalesPerHourComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
  })
}
</script>
